// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import SetAcceptanceRequestDTO from '../../dto/resource/SetAcceptanceRequestDTO';

export default abstract class SetAcceptanceRequestModel extends BaseModel<SetAcceptanceRequestDTO> {
  /**
  */
  get acceptGlaromatSihaTermsOfCondition() { return this.dto.acceptGlaromatSihaTermsOfCondition; }
  set acceptGlaromatSihaTermsOfCondition(value) { this.dto.acceptGlaromatSihaTermsOfCondition = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = queries.acceptGlaromatSihaTermsOfCondition === 'true' ? true : queries.acceptGlaromatSihaTermsOfCondition;
    value = queries.acceptGlaromatSihaTermsOfCondition === 'false' ? false : undefined;
    this.dto.acceptGlaromatSihaTermsOfCondition = value;
  }
}
