







































































































import Vue from 'vue';
import SetAcceptanceRequestViewModel from '@/src/services/viewModel/resource/SetAcceptanceRequestViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    isAGBAccepted: false,
    requestAGB: new SetAcceptanceRequestViewModel(),
    isLoading: false,
  }),
  computed: {
    tenant() {
      return this.$route.params.tenant;
    },
    workspace() {
      return this.$route.params.workspace;
    },
  },
  async mounted() {
    this.isAGBAccepted = await this.$service.api.me.getMySiHaTerms();
    this.isLoading = false;
  },
  methods: {
    async acceptAGB() {
      if (this.requestAGB.acceptGlaromatSihaTermsOfCondition) {
        this.isLoading = true;
        try {
          await this.$service.api.me.setAcceptance(this.requestAGB);
          window.location.reload();
        } catch (e) {
          this.isLoading = false;
          throw e;
        }
        this.isLoading = false;
      }
    },
  },
});
